
































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { ITeamApiService } from '../../services/team.service';
import { PouleSelectListItem } from '../../models/poule-select-list-item.model';
import { Getter } from 'vuex-class';
import { TeamPouleResult, TeamPouleResults } from '../../models/team-poule-results.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';

@Component
export default class TeamPouleRanking extends Vue {

    rankingLoading = false;
    poulesLoading = false;

    pouleOptions: PouleSelectListItem[] = [];
    selectedPoule: string | null = null;

    rankingData: TeamPouleResults | null = null;

    readonly fields = [
        { key: 'index', label: '' },
        { key: 'teamName', label: `${this.$t('teams.ranking.teamName')}` },
        { key: 'played', label: `#`, thClass: 'text-center', tdClass: 'text-center' },
        { key: 'won', label: `${this.$t('teams.ranking.won')}`, thClass: 'text-center', tdClass: 'text-center' },
        { key: 'draw', label: `${this.$t('teams.ranking.draw')}`, thClass: 'text-center', tdClass: 'text-center' },
        { key: 'lost', label: `${this.$t('teams.ranking.lost')}`, thClass: 'text-center', tdClass: 'text-center' },
        { key: 'points', label: `${this.$t('teams.ranking.points')}`, thClass: 'text-center', tdClass: 'text-center' }
    ]

    @inject()
    private teamApiService!: ITeamApiService;

    @Prop()
    teamId!: string;
    @Prop({ default: null })
    federationTeamId!: string | null;
    
    @Getter
    private clubId!:string;


    async created () {
        await this.fetchPouleOptions();
    }

    async fetchPouleOptions() : Promise<void> {
        try {
            this.poulesLoading = true;
            const result = await this.teamApiService.getTeamPoulesForSelect(this.clubId, this.teamId);

            const resultFiltered = result.filter(x => !x.hideRankings);
            this.pouleOptions = resultFiltered;

            if (resultFiltered.length > 0) {
                this.selectedPoule = resultFiltered[0].id;
            }

        }
        catch {
            ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
        }
        finally {            
            this.poulesLoading = false;
        }
    }

    async fetchRanking() : Promise<void> {
        try {
            if (this.selectedPoule === null) {
                return;
            }

            this.rankingLoading = true;

            const result = await this.teamApiService.getTeamPouleRankings(this.clubId, this.selectedPoule);

            this.rankingData = result;
        }
        catch {
            ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
        }
        finally {            
            this.rankingLoading = false;
        }
    }

    get items () : TeamPouleResult[] {
        if (this.rankingData === null) {
            return [];
        }

        return this.rankingData.results;
    }

    isCurrentTeam(teamId: string) : boolean {
        if (this.teamId === teamId) {
            return true;
        }

        if (this.federationTeamId !== null && this.federationTeamId === teamId) {
            return true;
        }

        return false
    }

    @Watch('selectedPoule')
    async onSelectedPouleChange() : Promise<void> {
        await this.fetchRanking();
    }
}
