var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 pb-4" },
    [
      _c("h5", { staticClass: "col-12 pt-3 pb-3 text-center" }, [
        _vm._v(
          _vm._s(_vm.team.name) +
            " - " +
            _vm._s(_vm.$t("teams.taskTypes.header"))
        ),
      ]),
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "row d-flex justify-content-end" },
          [
            _c(
              "b-button",
              {
                staticClass: "mb-2",
                attrs: { variant: "success" },
                on: { click: _vm.switchCreateMode },
              },
              [_vm._v(" " + _vm._s(_vm.$t("shared.add")) + " ")]
            ),
          ],
          1
        ),
        _vm.isCreating
          ? _c("div", { staticClass: "row pt-3" }, [
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "new-task-type-name" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "new-task-type-name",
                          placeholder: _vm.$t("teams.taskTypes.name"),
                          required: "",
                        },
                        model: {
                          value: _vm.newForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.newForm, "name", $$v)
                          },
                          expression: "newForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-2 buttons" },
                [
                  !_vm.isCreatingRequest
                    ? _c("i", {
                        staticClass:
                          "option fas fa-save fa-md mx-2 text-success",
                        on: {
                          click: function ($event) {
                            return _vm.createType()
                          },
                        },
                      })
                    : _c("Loader"),
                  _c("i", {
                    staticClass: "option fas fa-ban fa-md mx-2 text-danger",
                    on: {
                      click: function ($event) {
                        return _vm.switchCreateMode()
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("b-table", {
        attrs: { items: _vm.teamTaskTypes, fields: _vm.fields },
        scopedSlots: _vm._u([
          {
            key: "cell(name)",
            fn: function (row) {
              return [
                !_vm.isEditing(row.item)
                  ? _c("div", [_vm._v(" " + _vm._s(row.item.name) + " ")])
                  : _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "task-type-name" } },
                          [
                            _c("b-form-input", {
                              attrs: { id: "task-type-name", required: "" },
                              model: {
                                value: _vm.editForm.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "name", $$v)
                                },
                                expression: "editForm.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]
            },
          },
          {
            key: "cell(key)",
            fn: function (row) {
              return [
                !_vm.isEditing(row.item)
                  ? _c("div", [_vm._v(" " + _vm._s(row.item.key) + " ")])
                  : _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "task-type-key" } },
                          [
                            _c("b-form-input", {
                              attrs: { id: "task-type-key" },
                              model: {
                                value: _vm.editForm.key,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "key", $$v)
                                },
                                expression: "editForm.key",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]
            },
          },
          {
            key: "cell(manage)",
            fn: function (row) {
              return [
                !_vm.isEditing(row.item)
                  ? _c(
                      "div",
                      { staticClass: "buttons d-flex justify-content-end" },
                      [
                        row.item.teamId
                          ? _c("i", {
                              staticClass:
                                "option fas fa-edit fa-md mx-2 text-warning",
                              on: {
                                click: function ($event) {
                                  return _vm.switchEditMode(row.item)
                                },
                              },
                            })
                          : _vm._e(),
                        !_vm.isDeletingRequests(row.item) && row.item.teamId
                          ? _c("i", {
                              staticClass:
                                "option fas fa-times fa-md mx-2 text-danger",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteType(row.item)
                                },
                              },
                            })
                          : row.item.teamId
                          ? _c(
                              "div",
                              { staticClass: "d-flex col-3" },
                              [_c("Loader")],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "buttons d-flex justify-content-end" },
                      [
                        !_vm.isEditingRequests(row.item)
                          ? _c("i", {
                              staticClass:
                                "option fas fa-save fa-md mx-2 text-success",
                              on: {
                                click: function ($event) {
                                  return _vm.editType(row.item)
                                },
                              },
                            })
                          : _c(
                              "div",
                              { staticClass: "d-flex col-6" },
                              [_c("Loader")],
                              1
                            ),
                        _c("i", {
                          staticClass:
                            "option fas fa-ban fa-md mx-2 text-danger",
                          on: {
                            click: function ($event) {
                              return _vm.switchEditMode(row.item)
                            },
                          },
                        }),
                      ]
                    ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-content-end" },
        [
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("shared.close")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }