var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 mt-3" }, [
    _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("h5", [_vm._v(_vm._s(_vm.$t("teams.members.header")))]),
        _c("div", {}, [
          _c("i", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true },
              },
            ],
            staticClass:
              "text-primary fas fa-lg fa-envelope-open-text send-email-icon",
            attrs: { title: _vm.$t("teams.members.emails.title") },
            on: { click: _vm.openEmailModal },
          }),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "row p-2" },
      [
        _c("b-table", {
          ref: "playersTable",
          staticClass: "table-hover bg-white border",
          attrs: {
            selectable: "",
            "thead-class": "small",
            "select-mode": _vm.selectMode,
            items: _vm.playersItems,
            fields: _vm.playersFields,
            busy: _vm.isLoading,
            "show-empty": "",
            "empty-text": _vm.$t("teams.members.noMembers"),
          },
          on: { "row-selected": _vm.onRowPlayersTableSelected },
          scopedSlots: _vm._u([
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center table-loader" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "Loading...",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(name)",
              fn: function (row) {
                return [
                  row.rowSelected
                    ? _c("span", [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v("✓"),
                        ]),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Selected"),
                        ]),
                      ])
                    : _c("span", [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v(" "),
                        ]),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Not selected"),
                        ]),
                      ]),
                  _vm._v(" " + _vm._s(row.item.fullName) + " "),
                ]
              },
            },
            {
              key: "head(name)",
              fn: function (data) {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "selectable-header",
                      attrs: { "aria-hidden": "true" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleRowsSelectionPlayersTable()
                        },
                      },
                    },
                    [_vm._v(_vm._s(data.label))]
                  ),
                ]
              },
            },
            {
              key: "cell(refereePoints)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.refereePoints) + " ")]
              },
            },
            {
              key: "cell(dutyPoints)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.dutyPoints) + " ")]
              },
            },
            {
              key: "cell(tasks)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.tasks) + " ")]
              },
            },
            {
              key: "cell(matchesAttendence)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.matchesAttendance) + " ")]
              },
            },
            {
              key: "cell(trainingsAttendance)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(row.item.trainingsAttendance) + " "),
                ]
              },
            },
            {
              key: "cell(money)",
              fn: function (row) {
                return [_vm._v(_vm._s(_vm.parseMoney(row.item.money)))]
              },
            },
            {
              key: "cell(memberDetails)",
              fn: function (row) {
                return [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.openModal(row.item)
                        },
                      },
                    },
                    [
                      _c("a", { staticClass: "details" }, [
                        _vm._v(_vm._s(_vm.$t("teams.members.details"))),
                      ]),
                    ]
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row p-2" },
      [
        _c("b-table", {
          ref: "assistansTable",
          staticClass: "table-hover bg-white border",
          attrs: {
            selectable: "",
            "thead-class": "small",
            "select-mode": _vm.selectMode,
            items: _vm.assistantsItems,
            fields: _vm.assistansFields,
            busy: _vm.isLoading,
            "show-empty": "",
            "empty-text": _vm.$t("teams.members.noMembers"),
          },
          on: { "row-selected": _vm.onRowAssistansTableSelected },
          scopedSlots: _vm._u([
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center table-loader" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "Loading...",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(name)",
              fn: function (row) {
                return [
                  row.rowSelected
                    ? _c("span", [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v("✓"),
                        ]),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Selected"),
                        ]),
                      ])
                    : _c("span", [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v(" "),
                        ]),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Not selected"),
                        ]),
                      ]),
                  _vm._v(" " + _vm._s(row.item.fullName) + " "),
                ]
              },
            },
            {
              key: "head(name)",
              fn: function (data) {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "selectable-header",
                      attrs: { "aria-hidden": "true" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleRowsSelectionAssistansTable()
                        },
                      },
                    },
                    [_vm._v(_vm._s(data.label))]
                  ),
                ]
              },
            },
            {
              key: "cell(refereePoints)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.refereePoints) + " ")]
              },
            },
            {
              key: "cell(dutyPoints)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.dutyPoints) + " ")]
              },
            },
            {
              key: "cell(tasks)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.tasks) + " ")]
              },
            },
            {
              key: "cell(matchesAttendence)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.matchesAttendance) + " ")]
              },
            },
            {
              key: "cell(trainingsAttendance)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(row.item.trainingsAttendance) + " "),
                ]
              },
            },
            {
              key: "cell(money)",
              fn: function (row) {
                return [_vm._v(_vm._s(_vm.parseMoney(row.item.money)))]
              },
            },
            {
              key: "cell(memberDetails)",
              fn: function (row) {
                return [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.openModal(row.item)
                        },
                      },
                    },
                    [
                      _c("a", { staticClass: "details" }, [
                        _vm._v(_vm._s(_vm.$t("teams.members.details"))),
                      ]),
                    ]
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }