var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 mt-3" }, [
    _c("div", { staticClass: "row" }, [
      _c("h5", { staticClass: "d-flex col-9 mt-3" }, [
        _vm._v(_vm._s(_vm.$t("teams.trainings.header"))),
      ]),
      _c(
        "div",
        {
          staticClass:
            "d-flex col-3 mt-3 custom-control custom-switch justify-content-end",
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.showHistory,
                expression: "showHistory",
              },
            ],
            staticClass: "custom-control-input",
            attrs: { type: "checkbox", id: "assigment-history-switch" },
            domProps: {
              checked: Array.isArray(_vm.showHistory)
                ? _vm._i(_vm.showHistory, null) > -1
                : _vm.showHistory,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.showHistory,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.showHistory = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.showHistory = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.showHistory = $$c
                  }
                },
                function ($event) {
                  return _vm.reloadTrainingData()
                },
              ],
            },
          }),
          _c(
            "label",
            {
              staticClass: "custom-control-label",
              attrs: { for: "assigment-history-switch" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("teams.trainings.showHistory")))]
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "row p-2" },
      [
        _c("b-table", {
          staticClass: "table-hover bg-white border",
          attrs: {
            items: _vm.teamTrainings,
            fields: _vm.fields,
            "thead-class": "small",
            busy: _vm.isLoading,
            "show-empty": "",
            "empty-text": _vm.$t("teams.trainings.noTrainings"),
          },
          scopedSlots: _vm._u([
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center table-loader" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "Loading...",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(startUtc)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(_vm._f("date")(row.item.startUtc)) + " "),
                ]
              },
            },
            {
              key: "cell(startUtcTime)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(_vm._f("time")(row.item.startUtc)) + " "),
                ]
              },
            },
            {
              key: "cell(endUtc)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(_vm._f("time")(row.item.endUtc)) + " "),
                ]
              },
            },
            {
              key: "cell(field)",
              fn: function (row) {
                return [
                  [
                    _vm._v(
                      _vm._s(
                        row.item.quarters
                          ? row.item.field + " (" + row.item.quarters + ")"
                          : "" + row.item.field
                      )
                    ),
                  ],
                ]
              },
            },
            {
              key: "cell(trainers)",
              fn: function (row) {
                return [
                  _vm._v(
                    " " + _vm._s(_vm._f("array")(row.item.trainersNames)) + " "
                  ),
                ]
              },
            },
            {
              key: "cell(attendance)",
              fn: function (row) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "attendance",
                      on: {
                        click: function ($event) {
                          return _vm.openAttendanceModal(row.item)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(row.item.presentPlayersTotal) +
                          " / " +
                          _vm._s(row.item.playersTotal) +
                          " "
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "cell(userPresence)",
              fn: function (row) {
                return [
                  row.item.canEditPresence &&
                  !_vm.changingPresencesRows.some(function (x) {
                    return x === row.index
                  })
                    ? _c("div", [
                        _c("div", { staticClass: "dropdown" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn bg-white border p-1 mt-1",
                              attrs: {
                                type: "button",
                                id: "dropdownMenuButton",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                              },
                              on: {
                                click: [
                                  function ($event) {
                                    return _vm.toggleShowDropdown(row.item.id)
                                  },
                                  function ($event) {
                                    $event.stopPropagation()
                                  },
                                ],
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-check mx-2",
                                class: {
                                  "presence-muted":
                                    row.item.userPresence !=
                                    _vm.reactionPresent,
                                  "text-success":
                                    row.item.userPresence ==
                                    _vm.reactionPresent,
                                },
                              }),
                              _c("i", {
                                staticClass: "fas fa-question mx-2",
                                class: {
                                  "presence-muted":
                                    row.item.userPresence !=
                                    _vm.reactionUnknown,
                                  "text-warning":
                                    row.item.userPresence ==
                                    _vm.reactionUnknown,
                                },
                              }),
                              _c("i", {
                                staticClass: "fas fa-times mx-2",
                                class: {
                                  "presence-muted":
                                    row.item.userPresence != _vm.reactionAbsent,
                                  "text-danger":
                                    row.item.userPresence == _vm.reactionAbsent,
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showDropdown(row.item.id),
                                  expression: "showDropdown(row.item.id)",
                                },
                              ],
                              staticClass: "dropdown-menu show",
                              attrs: {
                                "aria-labelledby": "dropdownMenuButton",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: [
                                      function () {
                                        return _vm.changePresenceStatus(
                                          row.item.id,
                                          _vm.presenceStatuses.Present,
                                          row.index
                                        )
                                      },
                                      function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-fw fa-check mx-2 text-success",
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("shared.presence.present"))
                                  ),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: [
                                      function () {
                                        return _vm.changePresenceStatus(
                                          row.item.id,
                                          _vm.presenceStatuses.Unknown,
                                          row.index
                                        )
                                      },
                                      function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-fw fa-question mx-2 text-warning",
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("shared.presence.unknown"))
                                  ),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: [
                                      function () {
                                        return _vm.changePresenceStatus(
                                          row.item.id,
                                          _vm.presenceStatuses.Absent,
                                          row.index
                                        )
                                      },
                                      function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-fw fa-times mx-2 text-danger",
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("shared.presence.absent"))
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm.changingPresencesRows.some(function (x) {
                        return x === row.index
                      })
                    ? _c("div", { staticClass: "col-12" }, [_c("Loader")], 1)
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
        _c(
          "b-container",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.teamTrainings.length > 0,
                expression: "teamTrainings.length > 0",
              },
            ],
          },
          [
            _c(
              "b-row",
              [
                _c("b-col", { staticClass: "p-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-inline w-100 align-items-center",
                    },
                    [
                      _c("b-form-select", {
                        staticStyle: { "max-width": "100px" },
                        attrs: { options: _vm.pageSizeOptions, size: "sm" },
                        model: {
                          value: _vm.currentPageSizeSelect,
                          callback: function ($$v) {
                            _vm.currentPageSizeSelect = $$v
                          },
                          expression: "currentPageSizeSelect",
                        },
                      }),
                      _c("span", { staticClass: "text-muted ml-2" }, [
                        _vm._v("resultaten op pagina"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "b-col",
                  { staticClass: "p-0" },
                  [
                    _c("b-pagination", {
                      attrs: {
                        "per-page": _vm.pagination.pageSize,
                        "total-rows": _vm.pagination.totalEntries,
                        align: "end",
                        size: "sm",
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }