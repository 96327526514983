var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 mt-3" }, [
    _c("div", { staticClass: "row" }, [
      _c("h5", { staticClass: "d-flex col-6 mt-3" }, [
        _vm._v(_vm._s(_vm.$t("teams.results.title"))),
      ]),
      _c(
        "div",
        {
          staticClass:
            "d-flex col-6 mt-3 custom-control custom-switch justify-content-end",
        },
        [
          _c("div", { staticClass: "custom-control custom-switch" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.onlyMineResults,
                  expression: "onlyMineResults",
                },
              ],
              staticClass: "custom-control-input",
              attrs: { type: "checkbox", id: "mine-results" },
              domProps: {
                checked: Array.isArray(_vm.onlyMineResults)
                  ? _vm._i(_vm.onlyMineResults, null) > -1
                  : _vm.onlyMineResults,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.onlyMineResults,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.onlyMineResults = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.onlyMineResults = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.onlyMineResults = $$c
                  }
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "mine-results" },
              },
              [_vm._v(_vm._s(_vm.$t("teams.results.onlyMineResultsToggle")))]
            ),
          ]),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "row p-2" },
      [
        _c("b-table", {
          staticClass: "table-hover bg-white border",
          attrs: {
            items: _vm.teamResultsFiltered,
            fields: _vm.fields,
            "thead-class": "small",
            busy: _vm.isLoading,
            "show-empty": "",
            "empty-text": _vm.$t("teams.results.noResults"),
          },
          scopedSlots: _vm._u([
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center table-loader" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "Loading...",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(date)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(_vm._f("date")(row.item.date)) + " "),
                ]
              },
            },
            {
              key: "cell(team)",
              fn: function (row) {
                return [
                  _c(
                    "span",
                    {
                      class: {
                        "font-weight-bold": _vm.isCurrentTeam(
                          row.item.homeTeam.id
                        ),
                      },
                    },
                    [_vm._v(_vm._s(row.item.homeTeam.name))]
                  ),
                ]
              },
            },
            {
              key: "cell(opponent)",
              fn: function (row) {
                return [
                  _c(
                    "span",
                    {
                      class: {
                        "font-weight-bold": _vm.isCurrentTeam(
                          row.item.awayTeam.id
                        ),
                      },
                    },
                    [_vm._v(_vm._s(row.item.awayTeam.name))]
                  ),
                ]
              },
            },
            {
              key: "cell(result)",
              fn: function (row) {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold",
                      class: _vm.resultClass(
                        row.item.homeTeam.id,
                        row.item.awayTeam.id,
                        row.item.resultStatus
                      ),
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getResult(
                              row.item.homeScore,
                              row.item.homePenalty
                            )
                          ) +
                          " - " +
                          _vm._s(
                            _vm.getResult(
                              row.item.awayScore,
                              row.item.awayPenalty
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }