


















































































import { DateHelper } from '@/shared/helpers/date.helper';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NewTeamDeposit } from '../../models/new-team-deposit.model';
import { TeamMemberDeposit } from '../../models/team-member-deposit.model';
import { TeamMemberDetails } from '../../models/team-member-details.model';
import { TeamRoleMemberType } from '../../models/team-role-member-type.enum';

@Component
export default class TeamMemberDetailsModal extends Vue {
  @Prop()
  teamId!: string;
  @Prop()
  memberId!: string;
  @Prop()
  memberRoleType!: TeamRoleMemberType;

  isLoading = true;
  hasAnyChanges = false;
  deletingRequests: TeamMemberDeposit[] = [];
  isCreating = false;
  isCreatingRequest = false;

  deposit = {
    amount: 0,
  }

  avatarProperties: any =  {
    width: 250, 
    height: 250,
  }

  readonly fields = [
    { key: 'date', label: this.$t('teams.members.date') },
    { key: 'amount', label: this.$t('teams.members.amount') },
    { key: 'actions', label: '' }
  ]

  switchCreateMode(): void {
    this.deposit.amount = 0.00;
    this.isCreating = !this.isCreating;
  }

  @Action
  private loadTeamMemberDetails!: (payload: { teamId: string, memberId: string}) => Promise<void>
  @Action
  private deleteTeamDeposit!: (payload: { teamId: string, memberId: string, depositId: string }) => Promise<void>
  @Action
  private createTeamDeposit!: (payload: { teamId: string, memberId: string, deposit: NewTeamDeposit }) => Promise<void>

  @Getter
  teamMemberDetails!: TeamMemberDetails | null;

  async created(): Promise<void> {
    try {
      await this.loadTeamMemberDetails({ teamId: this.teamId, memberId: this.memberId });
    } catch {
      this.$emit('close', { hasErrors: true });
    } finally {
      this.isLoading = false;
    }

    
  }

  closeModal(): void {
    this.$emit('close', { hasChanges: this.hasAnyChanges });
  }

  get amountValidationState(): boolean {
        return this.deposit.amount > 0 ? true : false
  }

  get showAssistantItems(): boolean | undefined {
    return this.teamMemberDetails?.showAssistantItems;
  }

  getDate(value: Date) : string {
        return DateHelper.parseDate(value);
  }

  async createType(): Promise<void> {
    try {
      if (!this.amountValidationState) {
        return;
      }
      const deposit: NewTeamDeposit = {
        amount: +Number(this.deposit.amount).toFixed(2) * 100,
      };
      this.isCreatingRequest = true;
      await this.createTeamDeposit({ teamId: this.teamId, memberId: this.memberId, deposit: deposit});
      await this.loadTeamMemberDetails({ teamId: this.teamId, memberId: this.memberId });
      this.hasAnyChanges = true;
      this.switchCreateMode();
    } catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    } finally {
      this.isCreatingRequest = false;
    }
  }

  async deleteDeposit(deposit: TeamMemberDeposit): Promise<void> {
    try {
      this.deletingRequests.push(deposit);
      const payload = {
        teamId: this.teamId,
        memberId: this.memberId,
        depositId: deposit.id,
      }
      await this.deleteTeamDeposit(payload);
      await this.loadTeamMemberDetails({ teamId: this.teamId, memberId: this.memberId });    
      this.hasAnyChanges = true;
    } catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    } finally {
      this.deletingRequests = this.deletingRequests.filter(x => x !== deposit);
    }
  }

}

