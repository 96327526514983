
















































































































import { PriceHelper } from '@/shared/helpers/price.helper';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TeamMemberListItem } from '../../models/team-member-list-item.model';
import { TeamMember } from '../../models/team-member.model';
import { TeamMembers } from '../../models/team-members.model';
import TeamMemberDetailsModal from './TeamMemberDetailsModal.vue';
import TeamMemberSendEmailModal from './TeamMemberSendEmailModal.vue';

@Component
export default class TeamMembersList extends Vue {
  isLoading = true;
  selectMode =  'multi';
  selectedAssistans: TeamMember[] = []
  selectedPlayers: TeamMember[] = []

  @Prop()
  teamId!: string;

  @Getter
  teamMembers!: TeamMembers;
  @Getter
  reloadTeamMembersList!: boolean;

  @Action
  private loadTeamMembers!: (payload: { teamId: string }) => Promise<void>

  @Watch('teamId')
  async teamChanged(): Promise<void> {
    await this.reloadTeamMembersData(); 
  }

  @Watch('reloadTeamMembersList')
  async reloadStatusChanged(): Promise<void> {
    if (this.reloadTeamMembersList) {
      this.$store.commit('setTeamMembersListReload', false)
      await this.reloadTeamMembersData();
    }
  }

  readonly playersFields = [
    { key: 'name', label: this.$t('teams.members.players'), thClass: 'member-type' },
    { key: 'refereePoints', label: this.$t('teams.members.refereePoints'), tdClass: 'text-center' },
    { key: 'dutyPoints', label: this.$t('teams.members.dutyPoints'), tdClass: 'text-center' },
    { key: 'tasks', label: this.$t('teams.members.tasks'), tdClass: 'text-center' },
    { key: 'matchesAttendence', label: this.$t('teams.members.matchesAttendence'), tdClass: 'text-center' },
    { key: 'trainingsAttendance', label: this.$t('teams.members.trainingsAttendance'), tdClass: 'text-center' },
    { key: 'money', label: this.$t('teams.members.money'), tdClass: 'text-right' },
    { key: 'memberDetails', label: '' }
  ]

  readonly assistansFields = [
    { key: 'name', label: this.$t('teams.members.assistans'), thClass: 'member-type' },
    { key: 'refereePoints', label: this.$t('teams.members.refereePoints'), tdClass: 'text-center' },
    { key: 'dutyPoints', label: this.$t('teams.members.dutyPoints'), tdClass: 'text-center' },
    { key: 'tasks', label: this.$t('teams.members.tasks'), tdClass: 'text-center' },
    { key: 'matchesAttendence', label: this.$t('teams.members.matchesAttendence'), tdClass: 'text-center' },
    { key: 'trainingsAttendance', label: this.$t('teams.members.trainingsAttendance'), tdClass: 'text-center' },
    { key: 'money', label: this.$t('teams.members.money'), tdClass: 'text-right' },
    { key: 'memberDetails', label: '' }
  ]

  async created(): Promise<void> {
    await this.reloadTeamMembersData();
  }

  get teamMembersAsTable(): any[] {
    const teamMembersAsTable = [ {key: 'players', values: this.teamMembers.players }, {key: 'assistants', values: this.teamMembers.assistants }];
    return teamMembersAsTable;
  }

  async reloadTeamMembersData(): Promise<void> {
    try {
      this.isLoading = true;
      await this.loadTeamMembers({teamId: this.teamId });
    } 
    catch(e) {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    } 
    finally {
      this.isLoading = false;
    }
  } 

  get selectedMembersIds(): string[] {
    const selectedMembers = this.selectedAssistans.concat(this.selectedPlayers);
    return [ ...new Set(selectedMembers.map(x => x.personId)) ]
  }

  onRowAssistansTableSelected(items: TeamMember[]): void {
    this.selectedAssistans = items;
  }

  onRowPlayersTableSelected(items: TeamMember[]): void {
    this.selectedPlayers = items;
  }

  toggleRowsSelectionPlayersTable(): void {
    if (this.selectedPlayers.length === this.teamMembers.players.length) {
      (this.$refs.playersTable as any).clearSelected();
    } else {
      (this.$refs.playersTable as any).selectAllRows();
    }
  }

  toggleRowsSelectionAssistansTable(): void {
    if (this.selectedAssistans.length === this.teamMembers.assistants.length) {
      (this.$refs.assistansTable as any).clearSelected();
    } else {
      (this.$refs.assistansTable as any).selectAllRows();
    }
  }

  openModal(member: TeamMember): void {
    this.$modal.show(TeamMemberDetailsModal, 
      { memberRoleType: member.teamRoleMemberType, memberId: member.personId, teamId: this.teamId }, 
      { }, 
      { 'before-close': this.onModalClosed }
      );
  }

  onModalClosed(e: { params: { hasChanges: boolean, hasErrors: boolean }; cancel: () => void; }): void {
    if (!e.params) {
      e.cancel();
    }
    if (e.params?.hasChanges) {
      this.reloadTeamMembersData();
    }
    if (e.params?.hasErrors) {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    }
  }

  openEmailModal(): void {
    if (this.selectedAssistans.length === 0 && this.selectedPlayers.length === 0) {
      ToastHelper.showError(this.$bvToast, this.$t('teams.members.emails.noPerson'));
      return;
    }

    this.$modal.show(TeamMemberSendEmailModal,
    { selectedAssistants: this.selectedAssistans, selectedPlayers: this.selectedPlayers, teamId: this.teamId }
    );
  }

  parseMoney(value?: number) : string {
    if (!value) {
      return '€ 0';
    }

    return '€ ' + PriceHelper.getPrice(value || null);
  }

  get playersItems() : TeamMemberListItem[] {
    if (!this.teamMembers?.players) {
      return [];
    }

    return this.teamMembers?.players;
  }

  
  get assistantsItems() : TeamMemberListItem[] {
    if (!this.teamMembers?.assistants) {
      return [];
    }

    return this.teamMembers?.assistants;
  }
}
