





























































import { ToastHelper } from '@/shared/helpers/toast.helper';
import { PouleSelectListItem } from '../../models/poule-select-list-item.model';
import { inject } from 'inversify-props';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { PouleMatch } from '../../models/poule-match.model';
import { ITeamApiService } from '../../services/team.service';

@Component
export default class TeamPouleUpcomingMatches extends Vue {
  pouleOptions: PouleSelectListItem[] = [];
  selectedPoule: string | null = null;
  showAll = false;
  isLoading = true;

  @Getter
  teamPouleUpocomingMatches!: PouleMatch[]
  @Getter
  clubId!: string;

  readonly fields: {key: string, label: string, tdClass?: string}[] =  [ 
    { key: 'date', label: `${this.$t('teams.upcomingPoulesMatches.date') }`, tdClass: 'date-no-wrap' },
    { key: 'teams', label: '' },
    { key: 'start', label: `${this.$t('teams.upcomingPoulesMatches.time') }`, tdClass: 'date date-no-wrap' }
  ];

  @Action
  loadPouleUpcomingMatches!: (payload: { teamId: string, pouleId: string, showAll: boolean}) => Promise<void>

  @Prop()
  teamId!: string;

  @inject()
  private teamApiService!: ITeamApiService;

  async created(): Promise<void> {
    try {
      this.isLoading = true;
      const result = await this.teamApiService.getTeamPoulesForSelect(this.clubId, this.teamId);

      this.pouleOptions = result;
    
      if (result.length > 0) {
        this.selectedPoule = result[0].id;
        //await this.fetchUpcomingMatches();
      }
    }
    catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    }
    finally {
      this.isLoading = false;
    }    
  }

  async fetchUpcomingMatches(): Promise<void> {
    try {
      if (this.selectedPoule === null) {
        return;
      }

      this.isLoading = true;
      
      await this.loadPouleUpcomingMatches({ teamId: this.teamId, pouleId: this.selectedPoule, showAll: this.showAll })
    }
    catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    }
    finally {
      this.isLoading = false;
    }
  }

  get selectedPouleName() : string | undefined {
    if (!this.selectedPoule) {
      return undefined;
    }

    return this.pouleOptions.find(x => x.id === this.selectedPoule)?.name;
  }

  
  @Watch('selectedPoule')
  async onSelectedPouleChange() : Promise<void> {
      await this.fetchUpcomingMatches();
  }
}

