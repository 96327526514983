var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.teamInfo
    ? _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-12 mt-2" }, [
            _c("h5", [_vm._v(" " + _vm._s(_vm.teamInfo.name))]),
          ]),
          _c("team-matches"),
          _c("TeamTrainings", { attrs: { teamId: _vm.teamId } }),
          _c("TeamTasks", { attrs: { teamId: _vm.teamId } }),
          _c("TeamMembersList", { attrs: { teamId: _vm.teamId } }),
          _c("team-poule-ranking", {
            attrs: {
              teamId: _vm.teamId,
              federationTeamId: _vm.teamInfo.federationTeamId,
            },
          }),
          _c("TeamResults", {
            attrs: {
              teamId: _vm.teamId,
              federationTeamId: _vm.teamInfo.federationTeamId,
            },
          }),
          _c("TeamPouleUpcomingMatches", { attrs: { teamId: _vm.teamId } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }