





































import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { minLength, required } from 'vuelidate/lib/validators';
import { Action } from 'vuex-class';
import { SendEmailRequest } from '../../models/send-email-request.model';
import { TeamMember } from '../../models/team-member.model';

@Component
export default class TeamMemberSendEmailModal extends Vue {
  @Validate({required, minLength: minLength(8)})
  title = '';
  @Validate({required, minLegth: minLength(10)})
  message = '';

  @Prop()
  teamId!: string;
  @Prop()
  selectedAssistants!: TeamMember[]
  @Prop()
  selectedPlayers!: TeamMember[];

  @Action
  sendEmail!: (payload: { teamId: string, request: SendEmailRequest }) => Promise<void>

  get recipients(): TeamMember[] {
    const allRecipients = this.selectedAssistants.concat(this.selectedPlayers);
    const distincted = allRecipients.filter((v, i, a) => a.findIndex(t => (t.personId === v.personId)) === i);
    return distincted;
  }

  close(): void {
    this.$emit('close');
  }

  async sendEmailConfirm(): Promise<void> {
    const confirmed = await this.$bvModal.msgBoxConfirm(
      `${this.$t('teams.members.emails.confirm')}`,
      { okTitle: `${this.$t('shared.confirm')}`, cancelTitle: `${this.$t('shared.close')}`}
    );
    if (!confirmed) {
      return;
    }

    try {
      const request: SendEmailRequest = {
        title: this.title,
        message: this.message,
        recipientsPersonsIds: this.recipients.map(x => x.personId)
      };
      await this.sendEmail({ teamId: this.teamId, request });
      ToastHelper.showSuccess(this.$root.$bvToast, this.$t('teams.members.emails.sentSuccessfully'));
      this.$emit('close');
    } catch {
      ToastHelper.showError(this.$root.$bvToast, this.$t('shared.errorMessage'));
    }
  }
}

