var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 pb-4" },
    [
      _c("h5", { staticClass: "col-12 pt-3 pb-3 text-center" }, [
        _vm._v(_vm._s(_vm.$t("teams.members.emails.title"))),
      ]),
      _c("div", { staticClass: "col-12 row p-0 m-0 mb-3" }, [
        _c(
          "div",
          { staticClass: "form-group p-0 m-0 col-12 mb-3" },
          [
            _c("b-form-input", {
              attrs: {
                type: "text",
                state: _vm.$v.title.$dirty ? !_vm.$v.title.$error : null,
                placeholder: _vm.$t("teams.members.emails.subject"),
              },
              model: {
                value: _vm.$v.title.$model,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.$v.title,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.title.$model",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-group p-0 m-0 col-12 mb-3" },
          [
            _c("b-form-textarea", {
              attrs: {
                state: _vm.$v.message.$dirty ? !_vm.$v.message.$error : null,
                placeholder: _vm.$t("teams.members.emails.message"),
                rows: "10",
                "no-resize": "",
              },
              model: {
                value: _vm.$v.message.$model,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.$v.message,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.message.$model",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "col-12 row text-primary font-weight-bold" }, [
        _vm._v(" " + _vm._s(_vm.$t("teams.members.emails.recipients")) + ": "),
      ]),
      _vm._l(_vm.recipients, function (recipient) {
        return _c("div", { key: recipient.id, staticClass: "col-12 row" }, [
          _vm._v(" - " + _vm._s(recipient.fullName) + " ("),
          _c("span", { staticClass: "font-grey" }, [
            _vm._v(
              _vm._s(
                recipient.primaryEmail
                  ? recipient.primaryEmail
                  : _vm.$t("teams.members.emails.noEmail")
              )
            ),
          ]),
          _vm._v(") "),
        ])
      }),
      _c(
        "div",
        { staticClass: "buttons col-12 d-flex mt-3 justify-content-end" },
        [
          _c("b-button", { staticClass: "mr-1", on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("shared.close"))),
          ]),
          _c(
            "b-button",
            {
              attrs: { variant: "success" },
              on: { click: _vm.sendEmailConfirm },
            },
            [_vm._v(_vm._s(_vm.$t("teams.members.emails.send")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }