































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ResultStatus } from '../../models/result-status.enum';
import { TeamResult } from '../../models/team-result.model';

@Component
export default class TeamResults extends Vue {
  isLoading = true;
  onlyMineResults = false;
  status = ResultStatus;

  readonly fields: {key: string, label: string, tdClass?: string, thStyle?: string}[] =  [ 
    { key: 'date', label: `${this.$t('teams.results.date')}`, tdClass: 'date-no-wrap', thStyle: 'width: 15%' },
    { key: 'team', label: `${this.$t('teams.results.teamName')}`, thStyle: 'width: 35%' },
    { key: 'opponent', label:`${this.$t('teams.results.opponent')}`, thStyle: 'width: 35%' },
    { key: 'result', label: `${this.$t('teams.results.result')}`,tdClass: 'text-center', thStyle: 'width: 15%; text-align: center;' }
  ];

  @Action
  loadTeamResults!: (teamId: string) => Promise<void>;

  @Getter
  teamResults!: TeamResult[];

  @Prop()
  teamId!: string;

  @Prop({ default: null })
  federationTeamId!: string | null;

  async created(): Promise<void> {
    await this.loadTeamResults(this.teamId);
    this.isLoading = false;
  }

  isCurrentTeam(teamId: string) : boolean {
    if (this.teamId === teamId) {
      return true;
    }

    if (this.federationTeamId !== null && this.federationTeamId === teamId) {
      return true;
    }

    return false
  }

  resultClass(homeTeamId: string, awayTeamId: string, result?: ResultStatus) : string {
    const isHomeTeam = homeTeamId === this.teamId || (this.federationTeamId && homeTeamId === this.federationTeamId);
    const isAwayTeam = awayTeamId === this.teamId || (this.federationTeamId && awayTeamId === this.federationTeamId);

    if (!result || result === ResultStatus.Draw) {
      return 'text-warning';
    }

    if (result === ResultStatus.HomeTeamWon && isHomeTeam) {
      return 'text-success';
    }

    if (result === ResultStatus.HomeTeamWon && isAwayTeam) {
      return 'text-danger';
    }

    return '';
  }

  get teamResultsFiltered () {
    if (!this.onlyMineResults) {
      return this.teamResults;
    }

    return this.teamResults.filter(x => 
      x.homeTeam.id === this.teamId
      || x.homeTeam.id === this.federationTeamId
      || x.awayTeam.id === this.teamId
      || x.awayTeam.id === this.federationTeamId);
  }

  getResult(score: number, penalty: number): string {
    let total = score ?? 0;
    if (penalty) {
      total - penalty;
    }

    if (total < 0) {
      return `(${total})`
    } else {
      return `${total}`;
    }
  }
}

