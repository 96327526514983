




































































































import { PresenceStatus } from '@/shared/enums/presence-status.enum';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Training } from '../../models/training.model';
import TrainingAttendanceModal from './TrainingAttendanceModal.vue';

@Component
export default class TeamTrainings extends Vue {
  readonly fields = [
    { key: 'startUtc', label: this.$t('teams.trainings.date'), tdClass: 'date-no-wrap vertical-align-middle' },
    { key: 'startUtcTime', label: this.$t('teams.trainings.start'), tdClass: 'date-no-wrap vertical-align-middle' },
    { key: 'endUtc', label: this.$t('teams.trainings.end'), tdClass: 'date-no-wrap vertical-align-middle' },
    { key: 'field', label: this.$t('teams.trainings.field'), tdClass: 'vertical-align-middle' },
    { key: 'trainers', label: this.$t('teams.trainings.trainers'), tdClass: 'vertical-align-middle' },
    { key: 'attendance', label: this.$t('teams.trainings.attendance'), tdClass: 'vertical-align-middle' },
    { key: 'userPresence', label: '' }
  ]

  showHistory = false;
  presenceStatuses = PresenceStatus;
  isLoading = true;
  changingPresencesRows: number[] = [];
  hasAnyChanges = false;
  
  reactionPresent = PresenceStatus.Present;
  reactionUnknown = PresenceStatus.Unknown;
  reactionAbsent = PresenceStatus.Absent;

  selectedDropdown: string | null = null;

  pageSizeOptions: {value: number, text: number}[] = [
    { value: 10, text: 10},
    { value: 25, text: 25},
    { value: 50, text: 50},
    { value: 100, text: 100}
  ]

  @Prop()
  teamId!: string;

  @Getter
  teamTrainings!: Training[];
  @Getter
  pagination!: any;

  @Action
  private loadTeamTrainings!: (payload: { teamId: string, showHistory: boolean, currentPage: number, pageSize: number }) => Promise<void>
  @Action
  private changeUserTrainingPresence!: (payload: { teamId: string, trainingId: string, status: PresenceStatus}) => Promise<void>

  @Watch('teamId')
  async teamChanged(): Promise<void> {
    await this.reloadTrainingData();
  }

  async created(): Promise<void> {
    await this.reloadTrainingData();
  }

  toggleShowDropdown(id: string) : void {
      if (!this.selectedDropdown || this.selectedDropdown !== id) {
        this.selectedDropdown = id;
        return;
      }
      
      this.selectedDropdown = null;
  }

  showDropdown(id: string) : boolean {
    if (!this.showDropdown) {
      return false;
    }

    return this.selectedDropdown === id;
  }

  get currentPage() : number {
    return this.pagination.pageNumber;
  }

  set currentPage(page: number) {
    this.$store.commit('setCurrentPage', page);
  }

  get currentPageSizeSelect() : number {
    return this.pagination.pageSize;
  }
  
  set currentPageSizeSelect(pagesize: number) {
    this.$store.commit('setCurrentPageSize', pagesize);
  }

  async reloadTrainingData(): Promise<void> {
    try {
      this.isLoading = true;
      this.changingPresencesRows = [];
      await this.loadTeamTrainings({teamId: this.teamId, showHistory: this.showHistory, currentPage: this.currentPage, pageSize: this.pagination.pageSize});
    } catch(e) {
      ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
    } finally {
      this.isLoading = false;
    }
  }

  async changePresenceStatus(trainingId: string, status: PresenceStatus, row: number): Promise<void> {
    try {
      const index = this.teamTrainings.findIndex(t => t.id === trainingId);
      const currentStatus = this.teamTrainings[index].userPresence;
      if (currentStatus === status) {
        return;
      }

      this.changingPresencesRows.push(row);
      const payload = {
        teamId: this.teamId,
        trainingId: trainingId,
        status: status
      }

      await this.changeUserTrainingPresence(payload);
      this.teamTrainings[index].userPresence = status;
      
      if (status === PresenceStatus.Present) {
        this.teamTrainings[index].teamPresentMembersTotal++;
      } else if (currentStatus === PresenceStatus.Present) {
        this.teamTrainings[index].teamPresentMembersTotal--;
      }
    } catch(e) {
      ToastHelper.showError(this.$bvToast, `${this.$t('shared.presenceChanged')}`)
    } finally {
      this.changingPresencesRows = this.changingPresencesRows.filter(x => x !== row);
      this.toggleShowDropdown(trainingId);
    }
  }

  openAttendanceModal(training: Training): void {
    if (!training.canEditPresence) {
      return;
    }
    this.$modal.show(TrainingAttendanceModal, { training: training, teamId: this.teamId }, { }, { 'before-close': this.onModalClosed });
  }

  onModalClosed(e: { params: { hasChanges: boolean, hasErrors: boolean }; cancel: () => void; }): void {
    if (!e.params) {
      e.cancel();
    }
    if (e.params?.hasChanges) {
      this.reloadTrainingData();
    }
    if (e.params?.hasErrors) {
      ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
    }
  }

  @Watch('currentPage')
  async currentPageChanged(): Promise<void> {
    await this.reloadTrainingData();
  }
  
  @Watch('currentPageSizeSelect')
  async currentPageSizeSelectChanged(): Promise<void> {
    await this.reloadTrainingData();
  }
}
