export class PriceHelper {
    static getPrice(input: number | null) {
        if (input === null) {
            return '';
        }

        if (('' + input).includes(',') || ('' + input).includes('.')) {
            return `${input.toFixed(2)}`.replace('.', ',').replace(',00', ',-');
        }

        return '' + input;
    }
}