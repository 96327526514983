var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "col-12 pb-4" },
        [
          _c("h5", { staticClass: "col-12 pt-3 pb-3 text-center" }, [
            _vm._v(" " + _vm._s(_vm.$t("teams.tasks.assignmentHeader")) + " "),
          ]),
          _c("h6", { staticClass: "col-12 pb-3 text-center" }, [
            _vm._v(
              " " +
                _vm._s(_vm.teamMatch.homeTeam) +
                " - " +
                _vm._s(_vm.teamMatch.opponentTeam) +
                " "
            ),
          ]),
          _vm._l(_vm.teamMatch.tasks, function (assignment, index) {
            return _c(
              "div",
              {
                key: assignment.id,
                staticClass: "col-12 row d-flex justify-content-between",
              },
              [
                _c("div", [
                  _c("b", [_vm._v(_vm._s(assignment.clubMemberName))]),
                  _vm._v(" "),
                  _c("i", [_vm._v("(" + _vm._s(assignment.roleName) + ")")]),
                  _vm._v(" - "),
                  _c("label", { staticClass: "task-name-label" }, [
                    _vm._v(_vm._s(assignment.taskName)),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _vm.loadingRows.some(function (x) {
                      return x === index
                    })
                      ? _c("Loader")
                      : _c("i", {
                          staticClass:
                            "delete-icon fas fa-ban fa-sm mx-2 text-danger",
                          on: {
                            click: function ($event) {
                              return _vm.deleteTask(assignment, index)
                            },
                          },
                        }),
                  ],
                  1
                ),
              ]
            )
          }),
          _c("br"),
          _c(
            "b-form",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "task-type-group",
                    label: _vm.$t("teams.tasks.taskLabel"),
                    "label-for": "task-type-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "task-type-input",
                      required: "",
                      options: _vm.teamTaskTypesOptions,
                    },
                    model: {
                      value: _vm.form.taskTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "taskTypeId", $$v)
                      },
                      expression: "form.taskTypeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "club-member-group",
                    label: _vm.$t("teams.tasks.personLabel"),
                    "label-for": "club-member-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "club-member-input",
                      required: "",
                      options: _vm.teamTaskMembersOptions,
                    },
                    model: {
                      value: _vm.form.clubMemberId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "clubMemberId", $$v)
                      },
                      expression: "form.clubMemberId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-buttons d-flex justify-content-end" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("shared.close")) + " ")]
                  ),
                  _vm.isAdding ? _c("Loader") : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled: !_vm.isFormValid(),
                      },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v(_vm._s(_vm.$t("shared.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      )
    : _c("div", { staticClass: "tt-loader" }, [_c("Loader")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }