var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading && _vm.teamMemberDetails
    ? _c("div", { staticClass: "col-12 pb-4" }, [
        _c("h5", { staticClass: "col-12 pt-3 pb-3 text-center" }, [
          _vm._v(" " + _vm._s(_vm.$t("teams.members.memberDetails")) + " "),
        ]),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row pb-2" }, [
            _vm.teamMemberDetails.avatarUrl
              ? _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "b-img",
                      _vm._b(
                        { attrs: { src: _vm.teamMemberDetails.avatarUrl } },
                        "b-img",
                        _vm.avatarProperties,
                        false
                      )
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("span", [_vm._v(_vm._s(_vm.teamMemberDetails.fullName))]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("span", { staticClass: "date-no-wrap" }, [
                    _vm._v(
                      _vm._s(_vm.getDate(_vm.teamMemberDetails.birthDate))
                    ),
                  ]),
                ]),
                _vm._l(
                  _vm.teamMemberDetails.emailAddresses,
                  function (emailAddress, index) {
                    return _c(
                      "div",
                      { key: "e_" + index, staticClass: "row" },
                      [
                        _vm.showAssistantItems || !emailAddress.isSecret
                          ? _c(
                              "span",
                              {
                                class: emailAddress.isPrimary
                                  ? "primary-contact"
                                  : "",
                              },
                              [_vm._v(_vm._s(emailAddress.value))]
                            )
                          : _vm._e(),
                      ]
                    )
                  }
                ),
                _vm._l(
                  _vm.teamMemberDetails.phoneNumbers,
                  function (phoneNumber, index) {
                    return _c(
                      "div",
                      { key: "ph_" + index, staticClass: "row" },
                      [
                        _vm.showAssistantItems || !phoneNumber.isSecret
                          ? _c(
                              "span",
                              {
                                class: phoneNumber.isPrimary
                                  ? "primary-contact"
                                  : "",
                              },
                              [_vm._v(_vm._s(phoneNumber.value))]
                            )
                          : _vm._e(),
                      ]
                    )
                  }
                ),
              ],
              2
            ),
          ]),
          _vm.showAssistantItems
            ? _c("div", { staticClass: "row" }, [
                _c("h5", { staticClass: "col-12 pt-3 pb-3 text-center" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("teams.members.moneyHeader")) + " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mb-2",
                        attrs: { variant: "success" },
                        on: { click: _vm.switchCreateMode },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("teams.members.addMoney")) + " "
                        ),
                      ]
                    ),
                    _vm.isCreating
                      ? _c("div", { staticClass: "row pt-3" }, [
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { id: "new-deposit-name" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "number",
                                      min: "0",
                                      step: ".01",
                                      id: "new-deposit-name",
                                      state: _vm.amountValidationState,
                                      placeholder: _vm.$t(
                                        "teams.members.amount"
                                      ),
                                      "aria-describedby":
                                        "new-deposit-validation-feedback",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.deposit.amount,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.deposit, "amount", $$v)
                                      },
                                      expression: "deposit.amount",
                                    },
                                  }),
                                  _c(
                                    "b-form-invalid-feedback",
                                    {
                                      attrs: {
                                        id: "new-deposit-validation-feedback",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "teams.members.depositValidationMessage"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-2 buttons pt-2" },
                            [
                              !_vm.isCreatingRequest
                                ? _c("i", {
                                    staticClass:
                                      "option fas fa-save fa-lg mx-1 text-success",
                                    on: {
                                      click: function ($event) {
                                        return _vm.createType()
                                      },
                                    },
                                  })
                                : _c("Loader"),
                              _c("i", {
                                staticClass:
                                  "option fas fa-ban fa-lg mx-1 text-danger",
                                on: {
                                  click: function ($event) {
                                    return _vm.switchCreateMode()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.teamMemberDetails.deposits.length > 0
                      ? _c(
                          "div",
                          [
                            _c("b-table", {
                              attrs: {
                                items: _vm.teamMemberDetails.deposits,
                                fields: _vm.fields,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "cell(date)",
                                    fn: function (row) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getDate(row.item.date)) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "cell(amount)",
                                    fn: function (row) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(row.item.amount) + " "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "cell(actions)",
                                    fn: function (row) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "buttons" },
                                          [
                                            !_vm.deletingRequests.some(
                                              function (x) {
                                                return x === row.item
                                              }
                                            )
                                              ? _c("i", {
                                                  staticClass:
                                                    "option fas fa-times fa-md mx-2 text-danger col-2 buttons",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteDeposit(
                                                        row.item
                                                      )
                                                    },
                                                  },
                                                })
                                              : _c("Loader", {
                                                  staticClass: "col-2",
                                                }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                336961272
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm.teamMemberDetails.deposits.length === 0
                      ? _c(
                          "div",
                          { staticClass: "no-records col-12 text-center" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("teams.members.noDepositsToShow")
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "col-12 mt-4" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary col-12",
              on: { click: _vm.closeModal },
            },
            [_vm._v(_vm._s(_vm.$t("shared.close")))]
          ),
        ]),
      ])
    : _c("div", { staticClass: "ta-loader" }, [_c("Loader")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }