var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 mt-3" }, [
    _c("div", { staticClass: "row d-flex align-items-center" }, [
      _c("h5", { staticClass: "d-flex col-6 mt-3" }, [
        _vm._v(_vm._s(_vm.$t("teams.upcomingPoulesMatches.title"))),
      ]),
      _c(
        "div",
        {
          staticClass:
            "d-flex col-6 mt-3 align-items-center  justify-content-end",
        },
        [
          _c(
            "div",
            { staticClass: "custom-control custom-switch" },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: { name: "check-button", switch: "" },
                  on: { change: _vm.fetchUpcomingMatches },
                  model: {
                    value: _vm.showAll,
                    callback: function ($$v) {
                      _vm.showAll = $$v
                    },
                    expression: "showAll",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("teams.upcomingPoulesMatches.showAll")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.pouleOptions.length > 0
            ? _c("b-form-select", {
                staticClass: "col-7 ml-2",
                attrs: {
                  options: _vm.pouleOptions,
                  "text-field": "name",
                  "value-field": "id",
                },
                model: {
                  value: _vm.selectedPoule,
                  callback: function ($$v) {
                    _vm.selectedPoule = $$v
                  },
                  expression: "selectedPoule",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm.selectedPouleName
      ? _c("div", { staticClass: "row col-12" }, [
          _c("h6", [_vm._v("(" + _vm._s(_vm.selectedPouleName) + ")")]),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "p-2 row" },
      [
        _c("b-table", {
          staticClass: "table-hover bg-white border",
          attrs: {
            items: _vm.teamPouleUpocomingMatches,
            fields: _vm.fields,
            "thead-class": "small",
            busy: _vm.isLoading,
            "show-empty": "",
            "empty-text":
              _vm.pouleOptions.length === 0
                ? _vm.$t("teams.ranking.noPoules")
                : _vm.$t("teams.upcomingPoulesMatches.noMatchesMessage"),
          },
          scopedSlots: _vm._u([
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center table-loader" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "Loading...",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(date)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(_vm._f("date")(row.item.start)) + " "),
                ]
              },
            },
            {
              key: "cell(teams)",
              fn: function (row) {
                return [
                  _c(
                    "span",
                    {
                      class: {
                        "font-weight-bold text-primary":
                          row.item.homeTeamId === _vm.teamId,
                      },
                    },
                    [_vm._v(_vm._s(row.item.homeTeamName))]
                  ),
                  _vm._v(" - "),
                  _c(
                    "span",
                    {
                      class: {
                        "font-weight-bold text-primary":
                          row.item.awayTeamId === _vm.teamId,
                      },
                    },
                    [_vm._v(_vm._s(row.item.awayTeamName))]
                  ),
                ]
              },
            },
            {
              key: "cell(start)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(_vm._f("time")(row.item.start)) + " "),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }