


























































































import { Team } from '@/modules/dashboard/models/team.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NewTaskType } from '../../models/new-task-type.model';
import { TeamTaskType } from '../../models/team-task-type.model';

@Component
export default class TeamTaskTypesModal extends Vue {
  readonly fields = [
    { key: 'name', label: this.$t('teams.taskTypes.name') },
    { key: 'manage', label: '' }
  ];

  isCreating = false;
  isCreatingRequest = false;
  editing: TeamTaskType[] = [];
  editingRequests: TeamTaskType[] = [];
  deletingRequests: TeamTaskType[] = [];
  editForm = {
    name: '',
    key: ''
  }

  newForm = {
    name: '',
    key: ''
  }

  @Getter
  selectedTeam!: (teamId: string) => Team;
  @Getter
  teamTaskTypes!: TeamTaskType[];

  @Action
  private loadTeamTaskTypes!: (teamId: string) => Promise<void>

  @Action
  private updateTaskType!: (payload: { teamId: string, taskTypeId: string, taskType: NewTaskType }) => Promise<void>

  @Action
  private deleteTaskType!: (payload: { teamId: string, taskTypeId: string }) => Promise<void>

  @Action
  private createTaskType!: (payload: { teamId: string, taskType: NewTaskType }) => Promise<void>

  async created(): Promise<void> {
    await this.loadTeamTaskTypes(this.teamId);
  }

  switchCreateMode(): void {
    this.newForm.name = '';
    this.newForm.key = '';
    this.isCreating = !this.isCreating;
  }

  switchEditMode(type: TeamTaskType): void {
    const exists = this.editing.some(x => x === type);
    if (exists) {
      this.editing = this.editing.filter(x => x !== type);
      this.clearForm();
    } else {
      this.editing.push(type);
      this.editForm.name = type.name;
      this.editForm.key = type.key;
    }
  }

  async createType(): Promise<void> {
    try {
      const taskType: NewTaskType = {
        name: this.newForm.name,
        key: this.newForm.key
      };
      this.isCreatingRequest = true;
      await this.createTaskType({ teamId: this.teamId, taskType });
      await this.loadTeamTaskTypes(this.teamId);
      this.switchCreateMode();
    } catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    } finally {
      this.isCreatingRequest = false;
    }
  }

  async editType(type: TeamTaskType): Promise<void> {
    try {
      const taskType: NewTaskType = {
        name: this.editForm.name,
        key: this.editForm.key
      };
      this.editingRequests.push(type);
      await this.updateTaskType({ teamId: this.teamId, taskTypeId: type.id, taskType });
      type.name = this.editForm.name;
      type.key = this.editForm.key;
      this.switchEditMode(type);
    } catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    } finally {
      this.editingRequests = this.editingRequests.filter(x => x !== type);
    }
  }

  async deleteType(type: TeamTaskType): Promise<void> {
    try {
      this.deletingRequests.push(type);
      await this.deleteTaskType({ teamId: this.teamId, taskTypeId: type.id });
      await this.loadTeamTaskTypes(this.teamId);
    } catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    } finally {
      this.deletingRequests = this.deletingRequests.filter(x => x !== type);
    }
  }

  isEditing(type: TeamTaskType): boolean {
    return this.editing.some(x => x === type);
  }

  isEditingRequests(type: TeamTaskType): boolean {
    return this.editingRequests.some(x => x === type);
  }

  isDeletingRequests(type: TeamTaskType): boolean {
    return this.deletingRequests.some(x => x === type);
  }

  clearForm(): void {
    this.editForm.name = '';
    this.editForm.key = '';
  }

  get teamId(): string {
    return this.$route.params.id;
  }

  get team(): Team | null {
    return this.selectedTeam(this.teamId);
  }
}
