var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.teamTrainingPresence && !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "col-12 pb-4" },
        [
          _c("h5", { staticClass: "col-12 pt-3 pb-3 text-center" }, [
            _vm._v(" " + _vm._s(_vm.$t("teams.trainings.attendance")) + " "),
          ]),
          _c("h6", { staticClass: "col-12 pb-3 text-center" }, [
            _vm._v(
              " " +
                _vm._s(_vm.training.field) +
                " - " +
                _vm._s(_vm._f("date")(_vm.training.startUtc)) +
                " (" +
                _vm._s(_vm._f("time")(_vm.training.startUtc)) +
                " - " +
                _vm._s(_vm._f("time")(_vm.training.endUtc)) +
                ") "
            ),
          ]),
          _c("div", {}, [
            _c("div", { staticClass: "col-12" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("shared.presence.present")) +
                  ": " +
                  _vm._s(_vm.presentPresences) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("shared.presence.unknown")) +
                  ": " +
                  _vm._s(_vm.unknownPresences) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("shared.presence.absent")) +
                  ": " +
                  _vm._s(_vm.absentPresences) +
                  " "
              ),
            ]),
          ]),
          _vm.teamTrainingPresence.playersPresence.length > 0
            ? _c("div", { staticClass: "col-12 mt-4 font-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.$t("teams.trainings.players")) + " "),
              ])
            : _vm._e(),
          _vm._l(
            _vm.teamTrainingPresence.playersPresence,
            function (user, index) {
              return _c(
                "div",
                {
                  key: "pp" + index,
                  staticClass: "col-12 d-flex justify-content-between",
                },
                [
                  _c(
                    "div",
                    { staticClass: "row col-9 d-flex align-items-center" },
                    [_vm._v(" " + _vm._s(user.fullName) + " ")]
                  ),
                  !_vm.changingPresenceUsers.some(function (x) {
                    return x === user.id
                  })
                    ? _c(
                        "div",
                        { staticClass: "row d-flex justify-content-end col-3" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn bg-white border p-1 mt-1",
                              attrs: {
                                type: "button",
                                id: "dropdownMenuButton",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                              },
                              on: {
                                click: [
                                  function ($event) {
                                    return _vm.toggleShowDropdown(user.id)
                                  },
                                  function ($event) {
                                    $event.stopPropagation()
                                  },
                                ],
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-check fa-sm mx-2",
                                class: {
                                  "text-muted":
                                    user.status != _vm.presenceStatuses.Present,
                                  "text-success":
                                    user.status == _vm.presenceStatuses.Present,
                                },
                              }),
                              _c("i", {
                                staticClass: "fas fa-question fa-sm mx-2",
                                class: {
                                  "text-muted":
                                    user.status != _vm.presenceStatuses.Unknown,
                                  "text-warning":
                                    user.status == _vm.presenceStatuses.Unknown,
                                },
                              }),
                              _c("i", {
                                staticClass: "fas fa-times fa-sm mx-2",
                                class: {
                                  "text-muted":
                                    user.status != _vm.presenceStatuses.Absent,
                                  "text-danger":
                                    user.status == _vm.presenceStatuses.Absent,
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showDropdown(user.id),
                                  expression: "showDropdown(user.id)",
                                },
                              ],
                              staticClass: "dropdown-menu show",
                              attrs: {
                                "aria-labelledby": "dropdownMenuButton",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: [
                                      function () {
                                        return _vm.changePresenceStatus(
                                          user.id,
                                          _vm.presenceStatuses.Present
                                        )
                                      },
                                      function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-fw fa-check fa-sm mr-2 text-success",
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("shared.presence.present"))
                                  ),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: [
                                      function () {
                                        return _vm.changePresenceStatus(
                                          user.id,
                                          _vm.presenceStatuses.Unknown
                                        )
                                      },
                                      function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-fw fa-question fa-sm mr-2 text-warning",
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("shared.presence.unknown"))
                                  ),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: [
                                      function () {
                                        return _vm.changePresenceStatus(
                                          user.id,
                                          _vm.presenceStatuses.Absent
                                        )
                                      },
                                      function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-fw fa-times fa-sm mr-2 text-danger",
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("shared.presence.absent"))
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "row col-3" },
                        [_c("Loader")],
                        1
                      ),
                ]
              )
            }
          ),
          _vm.teamTrainingPresence.staffPresence.length > 0
            ? _c("div", { staticClass: "col-12 mt-4 font-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.$t("teams.trainings.staff")) + " "),
              ])
            : _vm._e(),
          _vm._l(
            _vm.teamTrainingPresence.staffPresence,
            function (user, index) {
              return _c(
                "div",
                {
                  key: "sp" + index,
                  staticClass: "col-12 d-flex justify-content-between",
                },
                [
                  _c(
                    "div",
                    { staticClass: "row col-9 d-flex align-items-center" },
                    [_vm._v(" " + _vm._s(user.fullName) + " ")]
                  ),
                  !_vm.changingPresenceUsers.some(function (x) {
                    return x === user.id
                  })
                    ? _c(
                        "div",
                        { staticClass: "row d-flex justify-content-end col-3" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn bg-white border p-1 mt-1",
                              attrs: {
                                type: "button",
                                id: "dropdownMenuButton",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                              },
                              on: {
                                click: [
                                  function ($event) {
                                    return _vm.toggleShowDropdown(user.id)
                                  },
                                  function ($event) {
                                    $event.stopPropagation()
                                  },
                                ],
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-check fa-sm mx-2",
                                class: {
                                  "text-muted":
                                    user.status != _vm.presenceStatuses.Present,
                                  "text-success":
                                    user.status == _vm.presenceStatuses.Present,
                                },
                              }),
                              _c("i", {
                                staticClass: "fas fa-question fa-sm mx-2",
                                class: {
                                  "text-muted":
                                    user.status != _vm.presenceStatuses.Unknown,
                                  "text-warning":
                                    user.status == _vm.presenceStatuses.Unknown,
                                },
                              }),
                              _c("i", {
                                staticClass: "fas fa-times fa-sm mx-2",
                                class: {
                                  "text-muted":
                                    user.status != _vm.presenceStatuses.Absent,
                                  "text-danger":
                                    user.status == _vm.presenceStatuses.Absent,
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showDropdown(user.id),
                                  expression: "showDropdown(user.id)",
                                },
                              ],
                              staticClass: "dropdown-menu show",
                              attrs: {
                                "aria-labelledby": "dropdownMenuButton",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: [
                                      function () {
                                        return _vm.changePresenceStatus(
                                          user.id,
                                          _vm.presenceStatuses.Present
                                        )
                                      },
                                      function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-fw fa-check fa-sm mr-2 text-success",
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("shared.presence.present"))
                                  ),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: [
                                      function () {
                                        return _vm.changePresenceStatus(
                                          user.id,
                                          _vm.presenceStatuses.Unknown
                                        )
                                      },
                                      function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-fw fa-question fa-sm mr-2 text-warning",
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("shared.presence.unknown"))
                                  ),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: [
                                      function () {
                                        return _vm.changePresenceStatus(
                                          user.id,
                                          _vm.presenceStatuses.Absent
                                        )
                                      },
                                      function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-fw fa-times fa-sm mr-2 text-danger",
                                  }),
                                  _vm._v(
                                    _vm._s(_vm.$t("shared.presence.absent"))
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "row col-3" },
                        [_c("Loader")],
                        1
                      ),
                ]
              )
            }
          ),
          _c("div", { staticClass: "col-12 mt-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary col-12",
                on: { click: _vm.closeModal },
              },
              [_vm._v(_vm._s(_vm.$t("shared.close")))]
            ),
          ]),
        ],
        2
      )
    : _c("div", { staticClass: "ta-loader" }, [_c("Loader")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }