







































































import { PresenceStatus } from '@/shared/enums/presence-status.enum';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TrainingPresence } from '../../models/training-presence.model';
import { Training } from '../../models/training.model';

@Component
export default class TrainingAttendanceModal extends Vue {
  @Prop()
  teamId!: string;
  @Prop()
  training!: Training;

  hasAnyChanges = false;
  presenceStatuses = PresenceStatus;
  isLoading = true;
  changingPresenceUsers: string[] = [];  
  
  selectedDropdown: string | null = null;

  @Action
  loadTeamTrainingPresence!: (payload: { teamId: string, trainingId: string, occurenceId: string}) => Promise<void>
  @Action
  changeMemberTrainingPresence!: (payload: { teamId: string, trainingId: string, memberId: string, status: PresenceStatus}) => Promise<void>

  @Getter
  teamTrainingPresence!: TrainingPresence | null;

  async created(): Promise<void> {
    try {
      await this.loadTeamTrainingPresence({ teamId: this.teamId, trainingId: this.training.trainingId, occurenceId: this.training.id });
    } catch {
      this.$emit('close', { hasErrors: true });
    } finally {
      this.isLoading = false;
    }
  }
  
  toggleShowDropdown(id: string) : void {
    if (!this.selectedDropdown || this.selectedDropdown !== id) {
      this.selectedDropdown = id;
      return;
    }
    
    this.selectedDropdown = null;
  }

  showDropdown(id: string) : boolean {
    if (!this.showDropdown) {
      return false;
    }

    return this.selectedDropdown === id;
  }

  async changePresenceStatus(userId: string, status: PresenceStatus): Promise<void> {
    try {
      this.changingPresenceUsers.push(userId);
      await this.changeMemberTrainingPresence({ teamId: this.teamId, trainingId: this.training.id, memberId: userId, status})
      const playerPresence = this.teamTrainingPresence!.playersPresence.find(x => x.id === userId);
      const staffPresence = this.teamTrainingPresence!.staffPresence.find(x => x.id === userId);
      playerPresence ? playerPresence!.status = status : '';
      staffPresence ? staffPresence!.status = status : '';
      this.hasAnyChanges = true;
    } catch {
      ToastHelper.showError(this.$bvToast, `${this.$t('shared.errorMessage')}`);
    } finally {
      this.changingPresenceUsers = this.changingPresenceUsers.filter(x => x !== userId);
      this.toggleShowDropdown(userId);
    }
  }

  closeModal(): void {
    this.$emit('close', { hasChanges: this.hasAnyChanges });
  }

  get unknownPresences(): number {
    const players = this.teamTrainingPresence?.playersPresence.filter(x => x.status === PresenceStatus.Unknown).length ?? 0;
    const staff = this.teamTrainingPresence?.staffPresence.filter(x => x.status === PresenceStatus.Unknown).length ?? 0;

    return players + staff;
  }

  get presentPresences(): number {
    const players = this.teamTrainingPresence?.playersPresence.filter(x => x.status === PresenceStatus.Present).length ?? 0;
    const staff = this.teamTrainingPresence?.staffPresence.filter(x => x.status === PresenceStatus.Present).length ?? 0;

    return players + staff;
  }

  get absentPresences(): number {
    const players = this.teamTrainingPresence?.playersPresence.filter(x => x.status === PresenceStatus.Absent).length ?? 0;
    const staff = this.teamTrainingPresence?.staffPresence.filter(x => x.status === PresenceStatus.Absent).length ?? 0;

    return players + staff;
  }
}

