var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 mt-3" }, [
    _c("div", { staticClass: "row" }, [
      _c("h5", { staticClass: "d-flex col-8 mt-3" }, [
        _vm._v(_vm._s(_vm.$t("teams.ranking.header"))),
      ]),
      _c(
        "div",
        { staticClass: "d-flex col-4 mt-3 justify-content-end" },
        [
          _c("b-form-select", {
            attrs: {
              options: _vm.pouleOptions,
              "text-field": "name",
              "value-field": "id",
            },
            model: {
              value: _vm.selectedPoule,
              callback: function ($$v) {
                _vm.selectedPoule = $$v
              },
              expression: "selectedPoule",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "row p-2" },
      [
        _c("b-table", {
          staticClass: "table-hover bg-white border",
          attrs: {
            items: _vm.items,
            fields: _vm.fields,
            "thead-class": "small",
            busy: _vm.rankingLoading || _vm.poulesLoading,
            "show-empty": "",
            "empty-text":
              _vm.pouleOptions.length === 0
                ? _vm.$t("teams.ranking.noPoules")
                : _vm.$t("teams.ranking.noRankingFound"),
          },
          scopedSlots: _vm._u([
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center table-loader" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "Loading...",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(index)",
              fn: function (data) {
                return [_vm._v(_vm._s(data.index + 1))]
              },
            },
            {
              key: "cell(teamName)",
              fn: function (data) {
                return [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isCurrentTeam(data.item.teamId) ? "text-bold" : "",
                      ],
                    },
                    [_vm._v(_vm._s(data.item.teamName))]
                  ),
                ]
              },
            },
            {
              key: "cell(played)",
              fn: function (data) {
                return [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isCurrentTeam(data.item.teamId) ? "text-bold" : "",
                      ],
                    },
                    [_vm._v(_vm._s(data.item.played))]
                  ),
                ]
              },
            },
            {
              key: "cell(won)",
              fn: function (data) {
                return [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isCurrentTeam(data.item.teamId) ? "text-bold" : "",
                      ],
                    },
                    [_vm._v(_vm._s(data.item.won))]
                  ),
                ]
              },
            },
            {
              key: "cell(draw)",
              fn: function (data) {
                return [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isCurrentTeam(data.item.teamId) ? "text-bold" : "",
                      ],
                    },
                    [_vm._v(_vm._s(data.item.draw))]
                  ),
                ]
              },
            },
            {
              key: "cell(lost)",
              fn: function (data) {
                return [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isCurrentTeam(data.item.teamId) ? "text-bold" : "",
                      ],
                    },
                    [_vm._v(_vm._s(data.item.lost))]
                  ),
                ]
              },
            },
            {
              key: "cell(points)",
              fn: function (data) {
                return [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.isCurrentTeam(data.item.teamId) ? "text-bold" : "",
                      ],
                    },
                    [_vm._v(_vm._s(data.item.points))]
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }