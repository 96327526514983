





































































































import { Club } from '@/modules/login/models/club.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { inject } from 'inversify-props';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TeamTaskTableViewPerson } from '../../models/team-task-table-view-person.model';
import { TeamTaskTableViewTask } from '../../models/team-task-table-view-task.model';
import { identifier, ITeamTaskApiService } from '../../services/team-task-api.service';

@Component
export default class TeamTaskTableViewModal extends Vue {
  readonly playersFields = [
    { key: 'name', label: ''}
  ]

  readonly assistantsFields = [
    { key: 'name', label: ''}
  ]


  @inject(identifier)
  private teamTaskApiService!: ITeamTaskApiService

  @Action
  private deleteMatchTaskFromMember!: (payload: { teamId: string, matchId: string, taskId: string }) => Promise<void>

  @Prop()
  teamId!: string;
  @Prop()
  showHistory!: boolean;

  @Getter
  currentClub!: Club;

  players: TeamTaskTableViewPerson[] = [];
  assistants: TeamTaskTableViewPerson[] = [];
  canEdit = false;
  isLoading = true;
  deletingRequests: TeamTaskTableViewTask[] = [];

  async created(): Promise<void> {
    const response = await this.teamTaskApiService.getTeamTaskTableView(this.currentClub.id, this.teamId, this.showHistory);
    this.players = response.players;
    this.assistants = response.assistants;
    this.canEdit = response.canEdit;
    this.isLoading = false;
    this.prepareFields();
  }

  prepareFields(): void {
    const tasks = this.getUniqueTasks();
    tasks.forEach((element) => {
      const field = { key: element.typeId, label: element.name };
      this.playersFields.push(field);
      this.assistantsFields.push(field);
    });
    
  }

  async deleteTask(member: TeamTaskTableViewPerson, task: TeamTaskTableViewTask): Promise<void> {
    try {
      this.deletingRequests.push(task);
      const payload = {
        teamId: this.teamId,
        matchId: task.match.id,
        taskId: task.id,
      }
      await this.deleteMatchTaskFromMember(payload);
      const player = this.players.find(x => x.clubMemberId === member.clubMemberId)!;
      player.tasks = member.tasks.filter(x => x.id !== task.id);
      const assistant = this.assistants.find(x => x.clubMemberId === member.clubMemberId)!;
      assistant.tasks = assistant.tasks.filter(x => x.id !== task.id);
    } catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    } finally {
      this.deletingRequests = this.deletingRequests.filter(x => x !== task);
    }
  }

  getUniqueTasks(): {typeId: string, name: string}[] {
    const allTasks = this.players.flatMap(x => x.tasks).concat(this.assistants.flatMap(y => y.tasks));
    const uniqueTasksIds = allTasks.map(x => x.taskId).filter((value, index, self) => self.indexOf(value) === index);
    const uniqueTasks = uniqueTasksIds.map(x => allTasks.find(y => y.taskId === x)!);
    return uniqueTasks.map(x => ({ typeId: x.taskId, name: x.taskName }));
  }

  getTaskCounter(member: TeamTaskTableViewPerson, taskId: string): number {
    const counter = member.tasks.filter(x => x.taskId === taskId).length;
    return counter;
  }
}
