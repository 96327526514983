

















import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import TeamTrainings from './components/TeamTrainings.vue';
import TeamMembersList from './components/TeamMembersList.vue';
import TeamTasks from './components/TeamTasks.vue';
import TeamPouleRanking from './components/TeamPouleRanking.vue';
import { Team } from '../models/team.model';
import TeamPouleUpcomingMatches from './components/TeamPouleUpcomingMatches.vue';
import TeamResults from './components/TeamResults.vue';
import { ToastHelper } from '@/shared/helpers/toast.helper';

@Component({
  components: {
    TeamTrainings,
    TeamTasks,
    TeamMembersList,
    TeamPouleRanking,
    TeamPouleUpcomingMatches,
    TeamResults
  }
})
export default class TeamInfo extends Vue {
  @Getter
  teamInfo!: Team;

  @Action
  loadTeamInfo!: (payload: { teamId: string }) => Promise<void>
    
  @Action
  private setLoading!: (loading: boolean) => Promise<void>
  
  async created(): Promise<void> {
    
    await this.setLoading(true);

    await this.loadTeamInfo( { teamId: this.teamId })    
      .catch(() => {
        ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
      });

    await this.setLoading(false);
  }

  get teamId(): string {
    return this.$route.params.id;
  }

}
