var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12 team-tasks mt-3" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "d-flex col-6 mt-3" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t("teams.tasks.header")))]),
        _c("div", [
          _vm.teamInfo.isAssistant
            ? _c(
                "div",
                {
                  staticClass: "task-types-button",
                  on: { click: _vm.openTaskTypesModal },
                },
                [
                  _c("i", { staticClass: "fas fa-cogs fa-sm mx-2" }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("teams.tasks.manageTaskTypes")) + " "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "d-flex col-6 mt-3 custom-control custom-switch justify-content-end",
        },
        [
          _c("div", { staticClass: "d-inline-flex" }, [
            _c("div", { staticClass: "custom-control custom-switch" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.showHistory,
                    expression: "showHistory",
                  },
                ],
                staticClass: "custom-control-input",
                attrs: { type: "checkbox", id: "show-history" },
                domProps: {
                  checked: Array.isArray(_vm.showHistory)
                    ? _vm._i(_vm.showHistory, null) > -1
                    : _vm.showHistory,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.showHistory,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.showHistory = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.showHistory = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.showHistory = $$c
                      }
                    },
                    function ($event) {
                      return _vm.reloadTeamTasks()
                    },
                  ],
                },
              }),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: "show-history" },
                },
                [_vm._v(_vm._s(_vm.$t("teams.tasks.showHistory")))]
              ),
            ]),
            _c("div", { staticClass: "ml-2 custom-control custom-switch" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.expandAll,
                    expression: "expandAll",
                  },
                ],
                staticClass: "custom-control-input",
                attrs: { type: "checkbox", id: "expand-all" },
                domProps: {
                  checked: Array.isArray(_vm.expandAll)
                    ? _vm._i(_vm.expandAll, null) > -1
                    : _vm.expandAll,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.expandAll,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.expandAll = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.expandAll = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.expandAll = $$c
                    }
                  },
                },
              }),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: "expand-all" },
                },
                [_vm._v(_vm._s(_vm.$t("teams.tasks.showAll")))]
              ),
            ]),
          ]),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "row p-2" },
      [
        _c("b-table", {
          staticClass: "table-hover bg-white border",
          attrs: {
            fields: _vm.fields,
            items: _vm.teamTaskMatches,
            "thead-class": "small",
            busy: _vm.isLoading,
            "show-empty": "",
            "empty-text": _vm.$t("teams.tasks.noTasks"),
          },
          scopedSlots: _vm._u([
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center table-loader" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "Loading...",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "table-colgroup",
              fn: function (scope) {
                return _vm._l(scope.fields, function (field) {
                  return _c("col", {
                    key: field.key,
                    style: { width: field.width },
                  })
                })
              },
            },
            {
              key: "cell(date)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(_vm._f("date")(row.item.startUtc)))]
              },
            },
            {
              key: "cell(opponent)",
              fn: function (row) {
                return [
                  row.item.opponentClub
                    ? [
                        _vm._v(
                          _vm._s(row.item.opponentTeam) +
                            " (" +
                            _vm._s(row.item.opponentClub) +
                            ")"
                        ),
                      ]
                    : [_vm._v(_vm._s(row.item.opponentTeam))],
                ]
              },
            },
            {
              key: "cell(time)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(_vm._f("time")(row.item.startUtc)))]
              },
            },
            {
              key: "cell(location)",
              fn: function (row) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        row.item.location +
                          ", " +
                          (row.item.isHome
                            ? _vm.$t("teams.tasks.home")
                            : _vm.$t("teams.tasks.away"))
                      )
                  ),
                ]
              },
            },
            {
              key: "cell(field)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.field))]
              },
            },
            {
              key: "cell(tasks)",
              fn: function (row) {
                return [
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tasks d-flex",
                        class: { "text-danger": _vm.hasNotAppeared(row.item) },
                        on: { click: row.toggleDetails },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getUniqueTasksCount(row.item.tasks)) +
                            " " +
                            _vm._s(_vm.$t("teams.tasks.taskCounter")) +
                            " "
                        ),
                      ]
                    ),
                    row.item.canEditTasks
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "assign",
                            attrs: {
                              title: _vm.$t("teams.tasks.manageButton"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openAssignmentModal(row.item)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "text-primary fa fa-sm fa-pen cursor-pointer",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              },
            },
            {
              key: "row-details",
              fn: function (row) {
                return _vm._l(
                  _vm.getGroupedTasks(row.item.tasks),
                  function (task, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "w-100 pr-5 d-flex flex-inline justify-content-end align-items-center",
                      },
                      [
                        _c("span", [_vm._v(_vm._s(task.member))]),
                        task.presence === _vm.reactionPresent
                          ? _c("i", {
                              staticClass:
                                "fas fa-check fa-sm mx-2 text-success",
                            })
                          : task.presence === _vm.reactionAbsent
                          ? _c("i", {
                              staticClass:
                                "fas fa-times fa-sm mx-2 text-danger",
                            })
                          : _c("i", {
                              staticClass:
                                "fas fa-question fa-sm mx-2 text-warning",
                            }),
                        _c("span", [_vm._v(_vm._s(task.task))]),
                      ]
                    )
                  }
                )
              },
            },
          ]),
        }),
        _c("div", { staticClass: "col-12 d-flex justify-content-end" }, [
          _c(
            "label",
            {
              staticClass: "text-primary table-view-text",
              on: { click: _vm.openTableViewModal },
            },
            [_vm._v(" " + _vm._s(_vm.$t("teams.tasks.seeTableView")))]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }