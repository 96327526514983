











































































































import { PresenceStatus } from '@/shared/enums/presence-status.enum';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TeamTaskMatchTask } from '../../models/team-task-match-task.model';
import { TeamTaskMatch } from '../../models/team-task-match.model';
import TeamTaskAssignmentModal from './TeamTaskAssignmentModal.vue';
import TeamTaskTypesModal from './TeamTaskTypesModal.vue';
import TeamTaskTableViewModal from './TeamTaskTableViewModal.vue';
import { Team } from '../../models/team.model';

@Component
export default class TeamTasks extends Vue {
  expandAll = false;
  expandedRows: number[] = [];
  showHistory = false;
  presenceStatuses = PresenceStatus;
  isLoading = true;
  readonly fields = [
    { key: 'date', label: this.$t('teams.tasks.date'), tdClass: 'date-no-wrap', width: '12%' },
    { key: 'opponent', label: this.$t('teams.tasks.opponent'), width: '30%' },
    { key: 'time', label: this.$t('teams.tasks.time'), tdClass: 'date-no-wrap', width: '8%' },
    { key: 'location', label: this.$t('teams.tasks.location'), width: '20%' },
    { key: 'field', label: this.$t('teams.tasks.field'), width: '15%' },
    { key: 'tasks', label: this.$t('teams.tasks.tasks'), width: '15%' }
  ]

  reactionPresent = PresenceStatus.Present;
  reactionUnknown = PresenceStatus.Unknown;
  reactionAbsent = PresenceStatus.Absent;

  @Action
  private loadTeamTasks!: (payload: { teamId: string, showHistory: boolean }) => Promise<void>

  @Getter
  teamTaskMatches!: TeamTaskMatch[];

  @Prop()
  teamId!: string;
  @Getter
  teamInfo!: Team;

  @Watch('teamId')
  async teamChanged(): Promise<void> {
    await this.reloadTeamTasks();
  }

  async created(): Promise<void> {
    await this.reloadTeamTasks();
  }

  async reloadTeamTasks(): Promise<void> {
    try {
      this.isLoading = true;
      await this.loadTeamTasks({ teamId: this.teamId, showHistory: this.showHistory });
    } catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    } finally {
      this.expandedRows = [];
      this.isLoading = false;
    }
  }

  hasNotAppeared(match: TeamTaskMatch): boolean {
    return match.tasks.some(x => x.presenceStatus === PresenceStatus.Absent);
  }

  switchExpanded(index: number): void {
    const exists = this.expandedRows.findIndex(x => x === index) !== -1;
    if (exists) {
      this.expandedRows = this.expandedRows.filter(x => x !== index);
    } else {
      this.expandedRows.push(index);
    }
  }

  openAssignmentModal(match: TeamTaskMatch): void {
    this.$modal.show(TeamTaskAssignmentModal, { teamMatch: match, teamId: this.teamId });
  }

  openTaskTypesModal(): void {
    this.$modal.show(TeamTaskTypesModal);
  }

  openTableViewModal(): void {
    this.$modal.show(TeamTaskTableViewModal, { teamId: this.teamId, showHistory: this.showHistory }, { width: '90%'}, { 'before-close': this.reloadTeamTasks});
  }

  getGroupedTasks(tasks: TeamTaskMatchTask[]): { member: string, task: string, presence: PresenceStatus, counter: number }[] {
    const membersIds = tasks.map(x => x.clubMemberId).filter((value, index, self) => self.indexOf(value) === index);
    const taskTypesIds = tasks.map(x => x.taskId).filter((value, index, self) => self.indexOf(value) === index);
    const result = [];

    for (const memberId of membersIds) {
      const member = tasks.find(x => x.clubMemberId == memberId)!;
      for (const taskTypeId of taskTypesIds) {
        const taskTypeName = tasks.find(x => x.taskId == taskTypeId)!.taskName;
        result.push({ member: member.clubMemberName, task: taskTypeName, presence: member.presenceStatus, counter: tasks.filter(x => x.clubMemberId === memberId && x.taskId === taskTypeId).length })
      }
    }
    return result.filter(x => x.counter > 0);
  }

  getUniqueTasksCount(tasks: TeamTaskMatchTask[]): number {
    return tasks.map(x => x.taskId).filter((value, index, self) => self.indexOf(value) === index).length;
  }
  
  @Watch('expandAll')
    async expandAllChange() : Promise<void> {
      this.teamTaskMatches.forEach(i => {
        this.$set(i, '_showDetails', this.expandAll);
      });
    }
}
