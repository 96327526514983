var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 pb-4" },
    [
      _c("h5", { staticClass: "col-12 row mt-3" }, [
        _vm._v(_vm._s(_vm.$t("teams.tasks.tableViewHeader"))),
      ]),
      _c("h5", { staticClass: "col-12 row mt-3 sub-title" }, [
        _vm._v(" " + _vm._s(_vm.$t("teams.tasks.players")) + " "),
      ]),
      _c("b-table", {
        attrs: {
          fields: _vm.playersFields,
          items: _vm.players,
          busy: _vm.isLoading,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center table-loader" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "Loading...",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(name)",
              fn: function (row) {
                return [
                  _c(
                    "div",
                    { staticClass: "member", on: { click: row.toggleDetails } },
                    [_vm._v(_vm._s(row.item.clubMemberName))]
                  ),
                ]
              },
            },
            _vm._l(_vm.getUniqueTasks(), function (task) {
              return {
                key: "cell(" + task.typeId + ")",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.getTaskCounter(row.item, task.typeId)) +
                        "x "
                    ),
                  ]
                },
              }
            }),
            {
              key: "row-details",
              fn: function (row) {
                return [
                  row.item.tasks.length > 0
                    ? _c("div", { staticClass: "row font-weight-bold" }, [
                        _c("div", { staticClass: "col-3" }, [
                          _vm._v(_vm._s(_vm.$t("teams.tasks.tasks"))),
                        ]),
                        _c("div", { staticClass: "col-3" }, [
                          _vm._v(_vm._s(_vm.$t("teams.tasks.date"))),
                        ]),
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(_vm._s(_vm.$t("teams.tasks.match"))),
                        ]),
                        _c("div", { staticClass: "col-2" }),
                      ])
                    : _vm._e(),
                  _vm._l(row.item.tasks, function (task) {
                    return _c(
                      "div",
                      { key: task.id, staticClass: "row pb-2 pt-2" },
                      [
                        _c("div", { staticClass: "col-3 font-weight-bold" }, [
                          _vm._v(" " + _vm._s(task.taskName) + " "),
                        ]),
                        _c("div", { staticClass: "col-3 date-no-wrap" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("date")(task.match.startUtc)) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(
                            " " +
                              _vm._s(task.match.teamName) +
                              " - " +
                              _vm._s(task.match.opponentName) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-2" },
                          [
                            _vm.canEdit &&
                            !_vm.deletingRequests.some(function (x) {
                              return x === task
                            })
                              ? _c("i", {
                                  staticClass:
                                    "delete-icon fas fa-ban fa-sm mx-2 text-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTask(row.item, task)
                                    },
                                  },
                                })
                              : _vm.canEdit
                              ? _c("Loader", { staticClass: "col-6" })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("h5", { staticClass: "col-12 row mt-3 sub-title" }, [
        _vm._v(" " + _vm._s(_vm.$t("teams.tasks.staff")) + " "),
      ]),
      _c("b-table", {
        attrs: {
          fields: _vm.assistantsFields,
          items: _vm.assistants,
          busy: _vm.isLoading,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "table-busy",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center table-loader" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "Loading...",
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(name)",
              fn: function (row) {
                return [
                  _c(
                    "div",
                    { staticClass: "member", on: { click: row.toggleDetails } },
                    [_vm._v(_vm._s(row.item.clubMemberName))]
                  ),
                ]
              },
            },
            _vm._l(_vm.getUniqueTasks(), function (task) {
              return {
                key: "cell(" + task.typeId + ")",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.getTaskCounter(row.item, task.typeId)) +
                        "x "
                    ),
                  ]
                },
              }
            }),
            {
              key: "row-details",
              fn: function (row) {
                return [
                  row.item.tasks.length > 0
                    ? _c("div", { staticClass: "row font-weight-bold" }, [
                        _c("div", { staticClass: "col-3" }, [
                          _vm._v(_vm._s(_vm.$t("teams.tasks.tasks"))),
                        ]),
                        _c("div", { staticClass: "col-3" }, [
                          _vm._v(_vm._s(_vm.$t("teams.tasks.date"))),
                        ]),
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(_vm._s(_vm.$t("teams.tasks.match"))),
                        ]),
                        _c("div", { staticClass: "col-2" }),
                      ])
                    : _vm._e(),
                  _vm._l(row.item.tasks, function (task) {
                    return _c(
                      "div",
                      { key: task.id, staticClass: "row pb-2 pt-2" },
                      [
                        _c("div", { staticClass: "col-3 font-weight-bold" }, [
                          _vm._v(" " + _vm._s(task.taskName) + " "),
                        ]),
                        _c("div", { staticClass: "col-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("date")(task.match.startUtc)) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(
                            " " +
                              _vm._s(task.match.teamName) +
                              " - " +
                              _vm._s(task.match.opponentName) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-2" },
                          [
                            _vm.canEdit &&
                            !_vm.deletingRequests.some(function (x) {
                              return x === task
                            })
                              ? _c("i", {
                                  staticClass:
                                    "delete-icon fas fa-ban fa-sm mx-2 text-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTask(row.item, task)
                                    },
                                  },
                                })
                              : _vm.canEdit
                              ? _c("Loader", { staticClass: "col-6" })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }