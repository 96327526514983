




















































import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TeamTaskMatchTask } from '../../models/team-task-match-task.model';
import { TeamTaskMatch } from '../../models/team-task-match.model';
import { TeamTaskMember } from '../../models/team-task-member.model';
import { TeamTaskType } from '../../models/team-task-type.model';

@Component
export default class TeamTaskAssignmentModal extends Vue {
  isLoading = true;
  hasAnyChanges = false;
  form = {
    taskTypeId: '',
    clubMemberId: '',
  };
  loadingRows: number[] = [];
  isAdding = false;

  @Prop()
  teamId!: string;
  @Prop()
  teamMatch!: TeamTaskMatch;

  @Action
  private loadTeamTaskTypes!: (teamId: string) => Promise<void>
  @Action
  private loadTeamTaskMembers!: (teamId: string) => Promise<void>
  @Action
  private assignMatchTaskToMember!: (payload: { teamId: string, matchId: string, teamPersonId: string, taskTypeId: string}) => Promise<void>
  @Action
  private deleteMatchTaskFromMember!: (payload: { teamId: string, matchId: string, taskId: string }) => Promise<void>

  @Getter
  teamTaskTypes!: TeamTaskType[];
  @Getter
  teamTaskMembers!: TeamTaskMember[];

  async created(): Promise<void> {
    try {
      this.isLoading = true;
      await Promise.all([this.loadTeamTaskTypes(this.teamId), this.loadTeamTaskMembers(this.teamId)]);
    } catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    } finally {
      this.isLoading = false;
    }
  }

  async onSubmit(): Promise<void> {
    try {
      this.isAdding = true;
      const payload = {
        teamId: this.teamId,
        matchId: this.teamMatch.id,
        teamPersonId: this.form.clubMemberId,
        taskTypeId: this.form.taskTypeId
      }
      await this.assignMatchTaskToMember(payload);
      this.hasAnyChanges = true;
      this.$store.commit('setTeamMembersListReload', true);
      this.close();
    } catch(ex) {
      const message = ex == '409' ? this.$t('teams.tasks.alreadyExistsError') : this.$t('shared.errorMessage');
      ToastHelper.showError(this.$bvToast, message);
    } finally {
      this.isAdding = false;
    }
  }

  async deleteTask(task: TeamTaskMatchTask, index: number): Promise<void> {
    const payload = {
      teamId: this.teamId,
      matchId: this.teamMatch.id,
      taskId: task.id
    };
    try {
      this.loadingRows.push(index);
      await this.deleteMatchTaskFromMember(payload);
      this.teamMatch.tasks = this.teamMatch.tasks.filter(x => x !== task);
      ToastHelper.showSuccess(this.$bvToast, this.$t('teams.tasks.taskDeleted'));
      this.hasAnyChanges = true;
      this.$store.commit('setTeamMembersListReload', true);
    } catch (ex) {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
    } finally {
      this.loadingRows = this.loadingRows.filter(x => x !== index);
    }
  }

  close(): void {
    this.$emit('close', { hasAnyChanges: this.hasAnyChanges });
  }

  isFormValid(): boolean {
    return this.form.taskTypeId !== '' && this.form.clubMemberId !== '';
  }

  get teamTaskTypesOptions(): { text: string, value: string }[] {
    return this.teamTaskTypes.map(ttt => ({ text: ttt.name, value: ttt.id }));
  }

  get teamTaskMembersOptions(): { text: string, value: string }[] {
    return this.teamTaskMembers.map(ttt => ({ text: `${ttt.name} (${ttt.role})`, value: ttt.id }));
  }

}

